import { Injectable } from '@angular/core';
import { DbService } from '../base/db.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AppService } from '../base/app.service';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';

interface IBookingEntity {
	id? : number , name : string, note : string , email : string,
	area_id : number, status_id : number, booking_time_id : number 	,
	start_time : string, end_time : string ,
	user_amount : number , booking_type_id : number, booking_for_id : number ,
	booking_date : string
}

@Injectable({
	providedIn: 'root'
})
export class BookingService extends DbService  {

	private dbService : string = environment.dbService;
	
	constructor(h: HttpClient, a:AppService) {
		super(h, a);
	}

	public getByCode(code : string) : Observable<any>{

		if (!code) {
			throw new Error('Invalid booking Code');
		}

		let token: string = this.getToken();
		let headers = new HttpHeaders({ Authorization : "Bearer " + token });
		
		return this.httpClient.get(
			this.getServiceURL() + `/api/booking/code/${ code }`, { headers }
		);
	}

	public get(id : number) : Observable<any>{

		if (!id || id == 0) {
			throw new Error('Invalid areaId');
		}

		let token: string = this.getToken();
		let headers = new HttpHeaders({ Authorization : "Bearer " + token });
		
		return this.httpClient.get(
			this.getServiceURL() + '/api/booking/' + id.toString(), { headers }
		);
	}

	public gets(params : { search? : string, area_id?:number, booking_date?:string, email?:string, status?:number, start : number, limit : number }) : Observable<any>{

		
		let token: string = this.getToken();
		let headers = new HttpHeaders({ Authorization : "Bearer " + token });
		
		
		return this.httpClient.get(
			this.getServiceURL() + '/api/booking' + this.getQueryString(params), { headers }
		);
	}


	public create(params: IBookingEntity) : Observable<any>{

		
		let token: string = this.getToken();
		let headers = new HttpHeaders({ Authorization : "Bearer " + token });
		
		
		return this.httpClient.post(
			this.getServiceURL() + '/api/booking' , params,  { headers }
		);
	}

	public update(params: IBookingEntity) : Observable<any>{

		
		let token: string = this.getToken();
		let headers = new HttpHeaders({ Authorization : "Bearer " + token });
		
		
		return this.httpClient.put(
			this.getServiceURL() + '/api/booking' , params,  { headers }
		);
	}


	public delete(id:number) : Observable<any>{

		
		let token: string = this.getToken();
		let headers = new HttpHeaders({ Authorization : "Bearer " + token });
		
		
		return this.httpClient.delete(
			this.getServiceURL() + '/api/booking/' + id.toString() ,  { headers }
		);
	}



	public getRequestByDate(params : { from :string, to:string }) : Observable<any>{

		
		let token: string = this.getToken();
		let headers = new HttpHeaders({ Authorization : "Bearer " + token });
		
		
		return this.httpClient.get(
			this.getServiceURL() + '/api/booking/daterq/1' + this.getQueryString(params), { headers }
		);
	}


	public getApproveByDate(params : { from :string, to:string }) : Observable<any>{
		
		let token: string = this.getToken();
		let headers = new HttpHeaders({ Authorization : "Bearer " + token });
		
		
		return this.httpClient.get(
			this.getServiceURL() + '/api/booking/dateap/1' + this.getQueryString(params), { headers }
		);
	}


	public getSummaryUsers(params : { from :string, to:string }) : Observable<any>{
		
		let token: string = this.getToken();
		let headers = new HttpHeaders({ Authorization : "Bearer " + token });
		
		
		return this.httpClient.get(
			this.getServiceURL() + '/api/booking/suser/1' + this.getQueryString(params), { headers }
		);
	}







}
